import React from 'react';
import { navigate } from '@reach/router';

import { Button, MenuButton, Grid } from '../../components';
import closeMenuIcon from '../../assets/images/menu-close.svg';
import ApiService from '../../common/api';
import { translate } from '../../common/helpers';

export default class PriceList extends React.Component {
  tableCols = (type) => [
    { key: 'floornumber', label: this.t('AREA_FLOOR') },
    { key: 'number', label: this.t('PRICE_LIST_NUMBER') },
    { key: 'area', label: this.t('AREA_AREA') },
    { key: 'terrace', label: this.t('PRICE_LIST_TERRACE') },
    {
      key: 'sellingPrice',
      label: this.t('AREA_SELLING_PRICE'),
      render: (col, row) =>
        row.status === 'sold' ? this.t('AREA_STATUS_SOLD') : col,
    },
    {
      key: 'status',
      label: this.t('AREA_STATUS'),
      render: (col) => this.t(`AREA_STATUS_${col.toUpperCase()}`),
    },
    {
      key: 'action',
      label: '',
      render: (_, row) => (
        <Button
          block
          small
          disabled={['sold', 'rented'].includes(row.status)}
          onClick={(e) =>
            navigate(`/?area=${row.number}#sale-form`) && e.stopPropagation()
          }
          className="ava-saada-paring-vorm"
        >
          {this.t('PRICE_LIST_BUTTON_SEND')}
        </Button>
      ),
    },
  ];

  state = {
    types: [],
    areas: [],
  };

  async componentDidMount() {
    try {
      const areaRes = await ApiService.getAreas();
      const areas = areaRes.sort((a, b) =>
        +a.acf.number < +b.acf.number
          ? -1
          : +a.acf.number > +b.acf.number
          ? 1
          : 0,
      );

      this.setState({
        ...this.state,
        types: [...new Set(areaRes.map((item) => item.acf.type))],
        areas,
      });
    } catch (error) {
      console.log(error);
    }
  }

  t(key) {
    return translate(this.props.translations, key);
  }

  renderColumn(col, data, i) {
    return (
      <td key={i}>
        {col.label && (
          <strong className="table__cell-label">{col.label}</strong>
        )}
        {col.render ? col.render(data[col.key], data) : data[col.key]}
      </td>
    );
  }

  renderTable(data, type) {
    return (
      <div className="table">
        <table className="table__table">
          <thead>
            <tr>
              {this.tableCols(type).map((col, i) => (
                <th key={i}>{col.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(({ acf: row }, i) => (
              <tr
                // onClick={() =>
                //   !['sold', 'rented'].includes(row.status) &&
                //   toRoom(this.t('URL_FLOOR'), row.floor, row.number)
                // }
                className={
                  ['sold', 'rented'].includes(row.status) ? 'is-disabled' : ''
                }
                key={i}
              >
                {this.tableCols(type).map((col, i) =>
                  this.renderColumn(col, row, i),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderMenuButton(isMenuOpen, onMenuToggle) {
    return (
      <MenuButton
        isWhite
        fixed={isMenuOpen}
        isOpen={isMenuOpen}
        onToggle={onMenuToggle}
        className={`${!isMenuOpen && 'm-t-20'}`}
      />
    );
  }

  render() {
    const { isMenuOpen, onMenuToggle } = this.props;
    const { areas, types } = this.state;

    return (
      <div className="container">
        {isMenuOpen && this.renderMenuButton(isMenuOpen, onMenuToggle)}
        <Grid align="between-xs">
          <Grid.Col width="xs-auto">
            <Button
              onClick={() => window.history.back()}
              icon
              className="m-t-30 m-b-20 m-b-sm-40 p-l-20 p-l-sm-0"
            >
              <img src={closeMenuIcon} alt="Close" loading="lazy" />
            </Button>
          </Grid.Col>
          <Grid.Col width="xs-auto" className="text-right">
            {!isMenuOpen && this.renderMenuButton(isMenuOpen, onMenuToggle)}
          </Grid.Col>
        </Grid>
        <h1 className="m-t-40 m-t-sm-80 m-b-40 m-b-sm-70">
          {this.t('PRICE_LIST_HEADER')}
        </h1>
        {types.includes('restaurant') && (
          <>
            <h2 className="m-b-40">{this.t('PRICE_LIST_TYPE_RESTAURANT')}</h2>
            <div className="m-b-100">
              {this.renderTable(
                [areas.find((area) => area.acf.type === 'restaurant')],
                'restaurant',
              )}
              <p className="text-right f-smallest color-beige">
                {this.t('PRICE_LIST_TABLE_BOTTOM_TEXT')}
              </p>
            </div>
          </>
        )}
        {types.includes('retail') && (
          <>
            <h2 className="m-b-40">{this.t('PRICE_LIST_TYPE_RETAIL')}</h2>
            <div className="m-b-100">
              {this.renderTable(
                areas.filter((area) => area.acf.type === 'retail'),
                'retail',
              )}
              <p className="text-right f-smallest color-beige">
                {this.t('PRICE_LIST_TABLE_BOTTOM_TEXT')}
              </p>
            </div>
          </>
        )}
        {types.includes('office') && (
          <>
            <h2 className="m-b-40">{this.t('PRICE_LIST_TYPE_OFFICE')}</h2>
            <div className="m-b-100">
              {this.renderTable(
                areas.filter((area) => area.acf.type === 'office'),
                'office',
              )}
              <p className="text-right f-smallest color-beige">
                {this.t('PRICE_LIST_TABLE_BOTTOM_TEXT')}
              </p>
            </div>
          </>
        )}

        <h2 className="m-b-40">{this.t('PRICE_LIST_TYPE_PARKING')}</h2>
        <div className="m-b-100">
          <div className="table table--no-hover">
            <table className="table__table">
              <thead>
                <tr>
                  <th />
                  {/* <th>{this.t('AREA_RENTAL_PRICE')}</th> */}
                  <th className="table__cell-small">
                    {this.t('AREA_SELLING_PRICE')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-no-wrap">{this.t('PARKING_LABEL')}</td>
                  {/* <td>
                    <strong className="table__cell-label">
                      {this.t('AREA_RENTAL_PRICE')}
                    </strong>
                    {this.t('PARKING_RENT_PRICE')}
                  </td> */}
                  <td>
                    <strong className="table__cell-label">
                      {this.t('AREA_SELLING_PRICE')}
                    </strong>
                    {this.t('PARKING_PRICE')}
                  </td>
                </tr>
                <tr>
                  <td className="text-no-wrap">
                    {this.t('STORAGE_ROOM_LABEL')}
                  </td>
                  {/* <td>
                    <strong className="table__cell-label">
                      {this.t('AREA_RENTAL_PRICE')}
                    </strong>
                    {this.t('STORAGE_ROOM_RENT_PRICE')}
                  </td> */}
                  <td>
                    <strong className="table__cell-label">
                      {this.t('AREA_SELLING_PRICE')}
                    </strong>
                    {this.t('STORAGE_ROOM_PRICE')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-right f-smallest color-beige">
            {this.t('PRICE_LIST_TABLE_BOTTOM_TEXT')}
          </p>
        </div>
      </div>
    );
  }
}
