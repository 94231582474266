import React, { useState, useCallback } from 'react';
import { useLocation } from '@reach/router';

import { Button, Input, Textarea } from '../';
import ApiService from '../../common/api';
import { translate } from '../../common/helpers';

export default function ContactForm({
  buttonRight,
  areaNr,
  translations,
  isRent = false,
}) {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({});
  const name = useInput('');
  const email = useInput('');
  const phone = useInput('');
  const message = useInput('');
  const location = useLocation();

  const t = (key) => {
    return translate(translations, key);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name.value === '' || email.value === '') {
      return setError({
        [name.value === '' ? 'name' : 'email']: t('CONTACT_REQUIRED'),
      });
    }

    const searchParams = new URLSearchParams(location.search);
    const urlAreaNr = searchParams.get('area');

    const data = {
      isRent,
      areaNr: urlAreaNr || areaNr,
      contactName: name.value,
      contactEmail: email.value,
      contactPhone: phone.value || '-',
      contactMessage: message.value || '-',
    };

    try {
      await ApiService.sendEmail(data);
      setSubmitted(true);
      setError({});
      setTimeout(() => {
        setSubmitted(false);
        name.clear();
        email.clear();
        phone.clear();
        message.clear();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {submitted ? (
        <React.Fragment>
          <h2 className="color-beige">{t('CONTACT_RESULT_1')}</h2>
          <h2 className="color-beige">{t('CONTACT_RESULT_2')}</h2>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <form onSubmit={handleSubmit}>
            <Input
              value={name.value}
              onChange={name.onChange}
              placeholder={t('CONTACT_INPUT_NAME')}
              error={error.name}
            />
            <Input
              value={email.value}
              onChange={email.onChange}
              placeholder={t('CONTACT_INPUT_EMAIL')}
              error={error.email}
            />
            <Input
              value={phone.value}
              onChange={phone.onChange}
              placeholder={t('CONTACT_INPUT_PHONE')}
            />
            <Textarea
              value={message.value}
              onChange={message.onChange}
              placeholder={t('CONTACT_INPUT_MESSAGE')}
            />
            <div className={buttonRight ? 'text-right' : ''}>
              <Button type="submit" className="m-t-40 m-b-20 saada-paring">
                {t('CONTACT_BUTTON_SEND')}
              </Button>
            </div>
          </form>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const useInput = (initial) => {
  const isNumber = typeof initial === 'number';
  const [value, setValue] = useState(initial);
  const onChange = useCallback((e) => setValue(e.target.value), []);

  return {
    value,
    setValue,
    hasValue:
      value !== undefined &&
      value !== null &&
      (!isNumber ? value.trim && value.trim() !== '' : true),
    clear: useCallback(() => setValue(''), []),
    onChange,
    bindToInput: {
      onChange,
      value,
    },
    bind: {
      onChange: setValue,
      value,
    },
  };
};
